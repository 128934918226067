import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";

const EnrollStudentCertificateForm = ({
  certificateData,
  date,
  setIsCertificateUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLor, setIsLor] = useState(false);
  const [isInternship, setIsInternship] = useState(false);

  const certificateApi = () => {
    const url = "/batch/send-certificate";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = {
      email: certificateData.email,
      program: certificateData.program,
      name: certificateData.name,
      internCode: certificateData.internCode,
      gender: certificateData.gender,
      startdate: date.start,
      enddate: date.end,
      isInternship,
      isLor,
    };

    axios
      .post(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);

        setTimeout(() => {
          setIsLoading(false);
          setIsCertificateUpdate(p=>!p)
        }, 5000);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };

  const addCertificateData = () => {
    const url = "/batch/certificate-record";
    const formdata = {
      batchId: certificateData.batchId,
      userId: certificateData.userId,
      isLor,
      isInternship,
      username: certificateData.name,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .post(url, formdata, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
      })
      .catch((err) => {
        console.log("error");
        notifyDanger(err.response.message || "some error occured");
      });
  };

  const sendCertificate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    certificateApi();
    addCertificateData();
  };

  return (
    <>
      <div className="enroll_student_certificate_form_container scrollbar-template">
        <h2>Student Information</h2>
        <form onSubmit={sendCertificate}>
          <div className="enroll_student_certificate_form_input">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter student Name"
              value={certificateData.name}
              disabled
            />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>Gender</label>
            <select value={certificateData.gender} disabled>
              <option>{certificateData.gender}</option>
              <option>male</option>
              <option>female</option>
            </select>
            <BiChevronDown className="down_icon" />
          </div>
          <div className="enroll_student_certificate_form_input enroll_student_certificate_form_select">
            <label>Email</label>
            <input
              type="text"
              placeholder="Enter student mail ID"
              value={certificateData.email}
              disabled
            />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>Program</label>
            <select disabled>
              <option>{certificateData.program}</option>
            </select>
            <BiChevronDown className="down_icon" />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>Intern Code</label>
            <select disabled>
              <option>{certificateData.internCode}</option>
            </select>
            <BiChevronDown className="down_icon" />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>Start Date</label>
            <input
              type="text"
              placeholder="Enter student Name"
              value={date.start}
              disabled
            />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>End Date</label>
            <input
              type="text"
              placeholder="Enter student Name"
              value={date.end}
              disabled
            />
          </div>
          <div className="enroll_student_certificate_form_input">
            <label>Certificate</label>
            <div className="enroll_student_certificate_radio_div">
              <div className="enroll_student_certificate_radio_box">
                <input
                  type="checkbox"
                  checked={isInternship}
                  onChange={() => setIsInternship(!isInternship)}
                />
                <h4>Internship</h4>
              </div>
              <div className="enroll_student_certificate_radio_box">
                <input
                  type="checkbox"
                  checked={isLor}
                  onChange={() => setIsLor(!isLor)}
                />
                <h4>Letter Of Recommendation</h4>
              </div>
            </div>
          </div>
          <div className="enroll_student_certificate_send_button">
            <button disabled={isLoading}>
              {isLoading ? <SmallLoader color={"#fff"} /> : "Send"}
            </button>
          </div>
        </form>
        {/* <RxCross2 className="enroll_student_certificate_cross_icon" /> */}
      </div>
    </>
  );
};

export default EnrollStudentCertificateForm;
