import React, { useEffect, useState } from "react";
import { confirmToast, images, notifySuccess } from "../../actions/customFn";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogOut } from "../../actions/loginAction";
import { RxCross2 } from "react-icons/rx";
import { IoStar, IoStarOutline } from "react-icons/io5";
import axios from "../../axios";
import {
  DashboardTicketIcon,
  EmailIcon,
  EnrollmentIcon,
  Favourites,
  Forms,
  LeadEvaluationIcon,
  Leads,
  OverView,
  Resources,
  Team,
} from "../../asset/icons/Icon";

const NewSidebar = ({ open, setOpen }) => {
  const [leadToggle, setLeadToggle] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [teamToggle, setTeamToggle] = useState(false);
  const [favourite, setFavourite] = useState([]);
  // console.log(favourite);
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const profileData = useSelector((state) => state.profileData.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUpdate, setIsUpdate] = useState(false);
  const location = useLocation();
  const [ticketToggle, setTicketToggle] = useState(false);

  const handleCloseSidebar = () => {
    setOpen(false);
  };

  const handleStarClick = async (name, path) => {
    const url = "/page/add-favourite";
    const data = {
      userId: profileData._id,
      pathList: [
        {
          name: name,
          path: path,
        },
      ],
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    try {
      const res = await axios.post(url, data, config);
      notifySuccess(res.data.message);
      setIsUpdate((p) => !p);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAllFav = async () => {
      const url = "/page/all-favourite";
      const config = {
        params: {
          userId: profileData._id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
        },
      };
      try {
        const res = await axios.get(url, config);
        setFavourite(res.data.data.pathList);
      } catch (error) {
        console.log(error);
      }
    };
    if (profileData._id) {
      getAllFav();
    }
  }, [profileData, isUpdate]);

  const handleRemoveFav = async (id) => {
    const url = "/page/remove-favourite";
    const data = {
      userId: profileData._id,
      pathId: id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };

    try {
      const res = await axios.put(url, data, config);
      notifySuccess(res.data.message);
      setIsUpdate((p) => !p);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/leads")) {
      setLeadToggle(true);
    }
    if (location.pathname.includes("/forms")) {
      setFormToggle(true);
    }

    if (location.pathname.includes("/ticket")) {
      setTicketToggle(true);
    }
  }, [location.pathname]);

  return (
    <>
      <div className={`new_sidebar_container ${open && "active"}`}>
        <div className="new_sidebar_logo">
          <img src={images["new_side_oeson.png"]} alt="" />
        </div>
        <div className="new_sidebar_home_list">
          <h4>Home</h4>
          <div className="new_sidebar_home_listitem">
            {/* <img src={images["new_side_dash.png"]} alt="" /> */}
            <OverView />
            <p>Overview</p>
          </div>
          <div className="new_sidebar_home_listitem">
            <Favourites />
            {/* <img src={images["new_side_fav.png"]} alt="" /> */}
            <p>Favourites</p>
          </div>

          <div className="new_sidebar_home_listitem_favitem">
            {favourite.map((item) => (
              <div className="new_sidebar_home_listitem_favitem_list">
                <Link to={item.path}>
                  <p>
                    {item.name}

                    {/* <img src={images["new_side_star.png"]} alt="" /> */}
                  </p>
                </Link>
                <IoStar
                  onClick={() => handleRemoveFav(item._id)}
                  className="favitem_star"
                />
              </div>
            ))}
          </div>

          <div
            onClick={() => setFormToggle(!formToggle)}
            className={`new_sidebar_home_listitem ${
              formToggle && "active"
            } new_sidebar_home_listitem_lead`}
            // className="new_sidebar_home_listitem"
          >
            {/* {formToggle ? (
              <img src={images["new_side_form_active.png"]} alt="" />
            ) : (
              <img src={images["new_side_form.png"]} alt="" />
            )} */}
            <Forms />
            <p>Forms</p>
            {formToggle ? (
              <FaChevronUp className="new_sidebar_home_listitem_downicon" />
            ) : (
              <FaChevronDown className="new_sidebar_home_listitem_downicon" />
            )}
          </div>

          {formToggle && (
            <div className="new_slider_home_listitem_dropdown">
              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/payment-data" && "active"
                }`}
              >
                <div className="dot"></div>
                <NavLink to={"/forms/payment-data"}>
                  <p>Payment Data</p>
                </NavLink>

                {favourite.find(
                  (item) => item.path === "/forms/payment-data"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/payment-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Payment Data", "/forms/payment-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>
              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/enroll-data" && "active"
                }`}
              >
                <div className="dot"></div>
                <NavLink to={"/forms/enroll-data"}>
                  <p>Offer Letter</p>
                </NavLink>

                {favourite.find(
                  (item) => item.path === "/forms/enroll-data"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/enroll-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Offer Letter", "/forms/enroll-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>

              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/invoice-data" && "active"
                }`}
              >
                <div className="dot"></div>
                <NavLink to={"/forms/invoice-data"}>
                  <p>Invoice</p>
                </NavLink>

                {favourite.find(
                  (item) => item.path === "/forms/invoice-data"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/invoice-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Invoice", "/forms/invoice-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>

              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/ogtip-data" && "active"
                }`}
              >
                <div className="dot"></div>
                <NavLink to={"/forms/ogtip-data"}>
                  <p>OGTIP</p>
                </NavLink>

                {favourite.find((item) => item.path === "/forms/ogtip-data") ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/ogtip-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("OGTIP", "/forms/ogtip-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>
              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/enquiry-data" && "active"
                }`}
              >
                <div className="dot lastdiv"></div>
                <NavLink to={"/forms/enquiry-data"}>
                  <p>Program Enquiry</p>
                </NavLink>
                {favourite.find(
                  (item) => item.path === "/forms/enquiry-data"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/enquiry-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Program Enquiry", "/forms/enquiry-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>

              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/forms/brochure-data" && "active"
                }`}
              >
                <div className="dot lastdiv"></div>
                <NavLink to={"/forms/brochure-data"}>
                  <p>Brochure Data</p>
                </NavLink>
                {favourite.find(
                  (item) => item.path === "/forms/brochure-data"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/forms/brochure-data"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Program Enquiry", "/forms/brochure-data")
                    }
                    className="hover_star"
                  />
                )}
              </div>
            </div>
          )}
          <div
            onClick={() => setLeadToggle(!leadToggle)}
            className={`new_sidebar_home_listitem ${
              leadToggle && "active"
            } new_sidebar_home_listitem_lead`}
          >
            {/* {leadToggle ? (
              <img src={images["new_side_active.png"]} alt="" />
            ) : (
              <img src={images["new_side_lead.png"]} alt="" />
            )} */}
            <Leads />
            <p>Leads</p>
            {leadToggle ? (
              <FaChevronUp className="new_sidebar_home_listitem_downicon" />
            ) : (
              <FaChevronDown className="new_sidebar_home_listitem_downicon" />
            )}
          </div>
          {leadToggle && (
            <div className="new_slider_home_listitem_dropdown">
              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/leads/mylead" && "active"
                }`}
              >
                <div className="dot"></div>
                <NavLink to={"/leads/mylead"}>
                  <p>My Leads</p>
                </NavLink>

                {favourite.find((item) => item.path === "/leads/mylead") ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/leads/mylead"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() => handleStarClick("My Leads", "/leads/mylead")}
                    className="hover_star"
                  />
                )}
              </div>
              <div
                className={`new_slider_home_listitem_dropdownitem ${
                  location.pathname === "/leads/disputed-leads" && "active"
                }`}
              >
                <div className="dot lastdiv"></div>
                <NavLink to={"/leads/disputed-leads"}>
                  <p>Disputed Leads</p>
                </NavLink>

                {favourite.find(
                  (item) => item.path === "/leads/disputed-leads"
                ) ? (
                  <IoStar
                    className="favitem_star"
                    onClick={() => {
                      const currentPath = favourite.find(
                        (item) => item.path === "/leads/disputed-leads"
                      );
                      handleRemoveFav(currentPath._id);
                    }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() =>
                      handleStarClick("Disputed Leads", "/leads/disputed-leads")
                    }
                    className="hover_star"
                  />
                )}
              </div>
            </div>
          )}

          {localStorage.getItem("oeson_role") === "SalesOperation" && (
            <>
              <div
                onClick={() => setTicketToggle(!ticketToggle)}
                className={`new_sidebar_home_listitem ${
                  ticketToggle && "active"
                } new_sidebar_home_listitem_lead`}
              >
                {/* {leadToggle ? (
              <img src={images["new_side_active.png"]} alt="" />
              ) : (
                <img src={images["new_side_lead.png"]} alt="" />
                )} */}
                <DashboardTicketIcon />
                <p>Tickets</p>
                {ticketToggle ? (
                  <FaChevronUp className="new_sidebar_home_listitem_downicon" />
                ) : (
                  <FaChevronDown className="new_sidebar_home_listitem_downicon" />
                )}
              </div>

              {ticketToggle && (
                <div className="new_slider_home_listitem_dropdown">
                  <div
                    className={`new_slider_home_listitem_dropdownitem ${
                      location.pathname === "/ticket-queries" && "active"
                    }`}
                  >
                    <div className="dot"></div>
                    <NavLink to={"/ticket-queries"}>
                      <p>Ticket Queries</p>
                    </NavLink>

                    {favourite.find(
                      (item) => item.path === "/ticket-queries"
                    ) ? (
                      <IoStar
                        className="favitem_star"
                        onClick={() => {
                          const currentPath = favourite.find(
                            (item) => item.path === "/ticket-queries"
                          );
                          handleRemoveFav(currentPath._id);
                        }}
                      />
                    ) : (
                      <IoStarOutline
                        onClick={() =>
                          handleStarClick("My Leads", "/ticket-queries")
                        }
                        className="hover_star"
                      />
                    )}
                  </div>
                  <div
                    className={`new_slider_home_listitem_dropdownitem ${
                      location.pathname === "/ticket-chats" && "active"
                    }`}
                  >
                    <div className="dot lastdiv"></div>
                    <NavLink to={"/ticket-chats"}>
                      <p>Ticket Chats</p>
                    </NavLink>

                    {favourite.find((item) => item.path === "/ticket-chats") ? (
                      <IoStar
                        className="favitem_star"
                        onClick={() => {
                          const currentPath = favourite.find(
                            (item) => item.path === "/ticket-chats"
                          );
                          handleRemoveFav(currentPath._id);
                        }}
                      />
                    ) : (
                      <IoStarOutline
                        onClick={() =>
                          handleStarClick("Disputed Leads", "/ticket-chats")
                        }
                        className="hover_star"
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div
            className={`new_sidebar_home_listitem ${
              location.pathname === "/resources" ? "active" : ""
            }`}
          >
            <Resources />
            {/* <img src={images["new_side_resource.png"]} alt="" /> */}
            <NavLink to={"/resources"}>
              <p>Resources</p>
            </NavLink>
          </div>

          {localStorage.getItem("oeson_role") === "SalesManager" && (
            <>
              <div
                className={`new_sidebar_home_listitem ${
                  location.pathname === "/lead-evaluation" ? "active" : ""
                }`}
              >
                <LeadEvaluationIcon />
                {/* <img src={images["new_side_resource.png"]} alt="" /> */}
                <NavLink to={"/lead-evaluation"}>
                  <p>Lead Evaluation</p>
                </NavLink>
              </div>

              <div
                className={`new_sidebar_home_listitem ${
                  location.pathname === "/enroll-student" ? "active" : ""
                }`}
              >
                <EnrollmentIcon />
                {/* <img src={images["new_side_resource.png"]} alt="" /> */}
                <NavLink to={"/enroll-student"}>
                  <p>Enroll Students</p>
                </NavLink>
              </div>
            </>
          )}
          {localStorage.getItem("oeson_role") === "SalesOperation" && (
            <>
              <div
                className={`new_sidebar_home_listitem ${
                  location.pathname === "/email" ? "active" : ""
                }`}
              >
                <EmailIcon />
                {/* <img src={images["new_side_resource.png"]} alt="" /> */}
                <NavLink to={"/email"}>
                  <p>Emails</p>
                </NavLink>
              </div>

              <div
                className={`new_sidebar_home_listitem ${
                  location.pathname === "/enroll-student" ? "active" : ""
                }`}
              >
                <EnrollmentIcon />
                {/* <img src={images["new_side_resource.png"]} alt="" /> */}
                <NavLink to={"/enroll-student"}>
                  <p>Enroll Students</p>
                </NavLink>
              </div>
            </>
          )}
        </div>
        {/* <div className="new_sidebar_home_list new_sidebar_customer_list">
          <h4>Customers</h4>
          <div className="new_sidebar_home_listitem">
            <img src={images["new_side_student.png"]} alt="" />
            <p>Students</p>
          </div>
          <div className="new_sidebar_home_listitem">
            <img src={images["new_side_message.png"]} alt="" />
            <p>Messages</p>
          </div>
          <div className="new_sidebar_home_listitem">
            <img src={images["new_side_schedule.png"]} alt="" />
            <p>Scheduled</p>
          </div>
          <div className="new_sidebar_home_listitem">
            <img src={images["new_side_income.png"]} alt="" />
            <p>Income</p>
          </div>
        </div> */}
        {/* <div className="new_sidebar_home_list new_sidebar_customer_list">
          <h4>Teams</h4>
          <div className="new_sidebar_home_listitem">
            <img src={images["new_side_live.png"]} alt="" />
            <p>Sales</p>
          </div>
        </div> */}
        {localStorage.getItem("oeson_role") === "SalesManager" && (
          <div className="new_sidebar_home_list new_sidebar_team_list">
            <h4>Teams</h4>
            <div
              onClick={() => setTeamToggle(!teamToggle)}
              className={`new_sidebar_home_listitem ${
                teamToggle && "active"
              } new_sidebar_home_listitem_lead`}
            >
              {/* {leadToggle ? (
    <img src={images["new_side_active.png"]} alt="" />
  ) : (
    <img src={images["new_side_lead.png"]} alt="" />
  )} */}
              <Team />
              <p>Company</p>
              {teamToggle ? (
                <FaChevronUp className="new_sidebar_home_listitem_downicon" />
              ) : (
                <FaChevronDown className="new_sidebar_home_listitem_downicon" />
              )}
            </div>
            {teamToggle && (
              <div className="new_slider_home_listitem_dropdown">
                <div
                  className={`new_slider_home_listitem_dropdownitem ${
                    location.pathname === "/leads/mylead" && "active"
                  }`}
                >
                  <div className="dot"></div>
                  <NavLink to={"/company/hierarchy"}>
                    <p>Hierarchy</p>
                  </NavLink>

                  {favourite.find((item) => item.path === "/leads/mylead") ? (
                    <IoStar
                      className="favitem_star"
                      onClick={() => {
                        const currentPath = favourite.find(
                          (item) => item.path === "/leads/mylead"
                        );
                        handleRemoveFav(currentPath._id);
                      }}
                    />
                  ) : (
                    <IoStarOutline
                      onClick={() =>
                        handleStarClick("My Leads", "/leads/mylead")
                      }
                      className="hover_star"
                    />
                  )}
                </div>
                <div
                  className={`new_slider_home_listitem_dropdownitem ${
                    location.pathname === "/leads/disputed-leads" && "active"
                  }`}
                >
                  <div className="dot lastdiv"></div>
                  <NavLink to={"/company/employee"}>
                    <p>Employees</p>
                  </NavLink>

                  {favourite.find(
                    (item) => item.path === "/leads/disputed-leads"
                  ) ? (
                    <IoStar
                      className="favitem_star"
                      onClick={() => {
                        const currentPath = favourite.find(
                          (item) => item.path === "/leads/disputed-leads"
                        );
                        handleRemoveFav(currentPath._id);
                      }}
                    />
                  ) : (
                    <IoStarOutline
                      onClick={() =>
                        handleStarClick(
                          "Disputed Leads",
                          "/leads/disputed-leads"
                        )
                      }
                      className="hover_star"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div
          onClick={() => {
            confirmToast("Are you want to Logout?", () => {
              dispatch(asyncLogOut(navigate));
            });
          }}
          className="new_sidebar_logout"
        >
          <img src={images["new_side_logout.png"]} alt="logout.png" />

          <p>Log Out</p>
        </div>

        <div className="new_sidebar_cross">
          <RxCross2 onClick={handleCloseSidebar} />
        </div>

        {/* new_sidebar_cross_icon_here */}
      </div>
    </>
  );
};

export default NewSidebar;
