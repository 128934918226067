import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { asyncCountryData } from "../../actions/rootAction";
import { handleScrollToError, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import { IoChevronDown } from "react-icons/io5";
import SmallLoader from "../../utils/SmallLoader";

const EnrollStudentInvoice = ({ formData }) => {
  // const [show, setShow] = useState(false);
  const countryData = useSelector((state) => state.rootData.countryData);
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    address: "",
    program: "",
    joinDate: "",
    phone_no: "",
    phone_code: "",
    country: "",
    amount_paid: "",
    currency_symbol: "",
    due_amount: "",
    referral_code: "",
    discount_percent: "",
    payment_method: "",
  });

  const [formError, setFormError] = useState({});
  const invoiceErrors = {};

  const invoiceValidation = () => {
    if (formFields.name === "") {
      invoiceErrors.name = "Name is required";
    }
    if (formFields.email === "") {
      invoiceErrors.email = "Email is required";
    }
    if (formFields.address === "") {
      invoiceErrors.address = "Address is required";
    }
    if (formFields.program === "") {
      invoiceErrors.program = "program is required";
    }
    if (formFields.joinDate === "") {
      invoiceErrors.joinDate = "joinDate is required";
    }
    if (formFields.phone_no === "") {
      invoiceErrors.phone_no = "Phone_no is required";
    }
    if (formFields.phone_code === "") {
      invoiceErrors.phone_code = "Phone_code is required";
    }
    if (formFields.country === "") {
      invoiceErrors.country = "Country is required";
    }
    if (formFields.amount_paid === "") {
      invoiceErrors.amount_paid = "Amount paid is required";
    }
    if (formFields.currency_symbol === "") {
      invoiceErrors.currency_symbol = "Currency symbol is required";
    }
    if (formFields.due_amount === "") {
      invoiceErrors.due_amount = "Due amount is required";
    }
    // if (formFields.referral_code === "") {
    //   invoiceErrors.referral_code = "referral code is required";
    // }
    // if (formFields.discount_percent === "") {
    //   invoiceErrors.discount_percent = "discount percent is required";
    // }
    if (formFields.payment_method === "") {
      invoiceErrors.payment_method = "payment method is required";
    }
    setFormError(invoiceErrors);
  };

  const [gender, setGender] = useState("");

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [isGst, setIsGst] = useState(false);
  const [isInstallment, setIsInstallment] = useState(false);

  //

  useEffect(() => {
    if (formData) {
      const dateFormat = formData?.joining_date?.split("/").reverse().join("-");
      setFormFields({
        name: formData.username,
        email: formData.email,
        address: formData.address,
        program: formData.program_name,
        joinDate: dateFormat,
        phone_no: Number(formData?.contact_no?.split(" ")[1]) || "",
        phone_code: formData?.contact_no?.split(" ")[0] || "",
        country: formData.country,
        amount_paid: "",
        currency_symbol: "",
        due_amount: "",
        referral_code: "",
        discount_percent: "",
        payment_method: "",
      });
      setGender(formData.gender);
    }
  }, [formData]);

  const onDataChange = (e) => {
    const { value, name } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    dispatch(asyncCountryData());
  }, []);

  const handleSubmit = () => {
    invoiceValidation();
    if (Object.keys(invoiceErrors).length === 0) {
      setIsLoading(true);
      const data = {
        username: formFields.name,
        email: formFields.email,
        contact: formFields.phone_code + " " + formFields.phone_no,
        gender: gender,
        address: formFields.address,
        country: formFields.country,
        program_name: formFields.program,
        amount_paid: formFields.amount_paid,
        currency_symbol: formFields.currency_symbol,
        due_amount: formFields.due_amount,
        referral_code: formFields.referral_code,
        discount_percent: formFields.discount_percent,
        payment_method: formFields.payment_method,
        isGst,
        installment: isInstallment,
        joining_date: formFields.joinDate,
      };

      const url = `/user/send-invoice`;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };

      axios
        .post(url, data, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsLoading(false);
          initialState();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.response);
        });
    }
  };

  const initialState = () => {
    setIsGst(false);
    setIsInstallment(false);
  };

  useEffect(() => {
    if (Object.keys(formError).length) {
      handleScrollToError("form_error_div");
    }
  }, [formError]);

  const invoicePhoneError = formError.phone_code || formError.phone_no;

  return (
    <>
      <div className="enroll_student_invoice_container scrollbar-template">
        <h2>Payment Summary</h2>
        <div className="enroll_student_invoice_form_2col">
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Name</label>
            <input
              type="text"
              placeholder="Enter user name"
              value={formFields.name}
              name="name"
              onChange={onDataChange}
            />
            {formError.name && (
              <div className="form_error_div">{formError.name}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Email ID</label>
            <input
              type="email"
              placeholder="Enter Email Address"
              value={formFields.email}
              name="email"
              onChange={onDataChange}
            />
            {formError.email && (
              <div className="form_error_div">{formError.email}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Address</label>
            <input
              type="text"
              placeholder="Enter Address"
              value={formFields.address}
              name="address"
              onChange={onDataChange}
            />
            {formError.address && (
              <div className="form_error_div">{formError.address}</div>
            )}
          </div>

          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Gender</label>
            <div className="enroll_student_invoice_gender">
              <button
                onClick={() => setGender("Female")}
                className={`${gender === "Female" ? "active" : ""} `}
              >
                Female
              </button>
              <button
                onClick={() => setGender("Male")}
                className={`${gender === "Male" ? "active" : ""} `}
              >
                Male
              </button>
            </div>
            {formError.gender && (
              <div className="form_error_div">{formError.gender}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Phone Number</label>
            <div className="send_offer_letter_phone_input">
              <div className="send_offer_letter_phone_input_select">
                <select
                  name="phone_code"
                  onChange={onDataChange}
                  value={formFields.phone_code}
                >
                  <option value="">Select Phone code</option>
                  {countryData.map((data, i) => {
                    return (
                      <option value={data.value}>
                        {data.label}({data.value})
                      </option>
                    );
                  })}
                </select>
                <IoChevronDown className="down_icon" />
              </div>
              <input
                type="number"
                name="phone_no"
                value={formFields.phone_no}
                onChange={onDataChange}
                placeholder="Enter Phone Number"
              />
            </div>
            {invoicePhoneError && (
              <div className="form_error_div">{invoicePhoneError}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Country</label>
            <div className="enroll_student_invoice_select">
              <select
                name="country"
                value={formFields.country}
                onChange={onDataChange}
                id=""
              >
                <option value="">Select Country</option>
                {countryData.map((data, i) => {
                  return <option>{data.label}</option>;
                })}
              </select>
              <BiChevronDown className="down_icon" />
            </div>
            {formError.country && (
              <div className="form_error_div">{formError.country}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Program Name</label>
            <div className="enroll_student_invoice_select">
              <select
                name="program"
                value={formFields.program}
                onChange={onDataChange}
                id=""
              >
                <option value={""}>Select Program</option>
                <option value={"Data Science"}>Data Science</option>
                <option value={"Digital Marketing"}>Digital Marketing</option>
                <option value={"Data Analytics"}>Data Analytics</option>
                <option value={"Business Analytics"}>Business Analytics</option>
                <option value={"Cyber Security"}>Cyber Security</option>
                <option value={"Data Engineering"}>Data Engineering</option>
              </select>
              <BiChevronDown className="down_icon" />
            </div>
            {formError.program && (
              <div className="form_error_div">{formError.program}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Joining Date</label>
            <input
              type="date"
              name="joinDate"
              value={formFields.joinDate}
              onChange={onDataChange}
            />
            {formError.joinDate && (
              <div className="form_error_div">{formError.joinDate}</div>
            )}
          </div>
        </div>

        <div className="enroll_student_invoice_form_3col">
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Payment Method</label>
            <div className="enroll_student_invoice_select">
              <select
                name="payment_method"
                value={formFields.payment_method}
                onChange={onDataChange}
                id=""
              >
                <option value="">Select payment method</option>
                <option value="paypal">Paypal</option>
                <option value="razorpay">Razorpay</option>
              </select>
              <BiChevronDown className="down_icon" />
            </div>
            {formError.payment_method && (
              <div className="form_error_div">{formError.payment_method}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Amount Paid</label>
            <input
              type="number"
              name="amount_paid"
              value={formFields.amount_paid}
              onChange={onDataChange}
              placeholder="Amount Paid"
            />
            {formError.amount_paid && (
              <div className="form_error_div">{formError.amount_paid}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Due Amount</label>
            <input
              type="number"
              placeholder="Enter Due Amount"
              name="due_amount"
              value={formFields.due_amount}
              onChange={onDataChange}
            />
            {formError.due_amount && (
              <div className="form_error_div">{formError.due_amount}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Current Symbol</label>
            <input
              type="text"
              placeholder="Enter Currency Symbol"
              name="currency_symbol"
              value={formFields.currency_symbol}
              onChange={onDataChange}
            />
            {formError.currency_symbol && (
              <div className="form_error_div">{formError.currency_symbol}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Referral Code</label>
            <input
              type="text"
              placeholder="Enter Coupon Code"
              name="referral_code"
              value={formFields.referral_code}
              onChange={onDataChange}
            />
            {formError.referral_code && (
              <div className="form_error_div">{formError.referral_code}</div>
            )}
          </div>
          <div className="enroll_student_invoice_input_div">
            <label htmlFor="">Discount Percentage</label>
            <input
              type="number"
              placeholder="Enter Coupon Code"
              name="discount_percent"
              value={formFields.discount_percent}
              onChange={onDataChange}
            />
            {formError.discount_percent && (
              <div className="form_error_div">{formError.discount_percent}</div>
            )}
          </div>
        </div>

        <div className="enroll_student_invoice_switch_option">
          <div className="enroll_student_invoice_switch_option_div">
            Installment
            <label class="enroll_student_switch">
              <input
                type="checkbox"
                checked={isInstallment}
                onChange={() => setIsInstallment((p) => !p)}
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div className="enroll_student_invoice_switch_option_div">
            GST
            <label class="enroll_student_switch">
              <input
                type="checkbox"
                checked={isGst}
                onChange={() => setIsGst((p) => !p)}
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div className="enroll_student_invoice_send_button">
          <button className="d-none">Preview</button>
          <button onClick={handleSubmit}>
            {isLoading ? <SmallLoader color={"#024c87"} /> : "Send Invoice"}
          </button>
        </div>

        {/* Start here.... */}

        {/* .enroll_student_invoice 
        <table>
          <thead>
            <tr>
              <th>Installment</th>
              <th>Title</th>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>Due Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>First</td>
              <td>Course Fee</td>
              <td>14/09/2001</td>
              <td>Razorpay</td>
              <td>20,000</td>
              <td>5,000</td>
            </tr>
          </tbody>
        </table> */}

        {/* end here */}
      </div>
    </>
  );
};

export default EnrollStudentInvoice;
