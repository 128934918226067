import React from "react";
import { BiSearch } from "react-icons/bi";

const EnrollStudentHeader = ({formData}) => {
  return (
    <>
      <div className="enroll_student_details_header">
        <h2>{formData?.username}</h2>
        {/* <div className="enroll_student_details_search_input">
          <input type="text" placeholder="Search" />
          <BiSearch className="search_icon" />
        </div>
        <label class="enroll_student_switch">
          <input type="checkbox" />
          <span class="slider round"></span>
        </label> */}
      </div>
    </>
  );
};

export default EnrollStudentHeader;
