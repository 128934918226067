import React, { useEffect, useState } from "react";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";

const EnrollStudentCertificate = ({
  certificateInfo,
  certificateData,
  date,
}) => {
  console.log(certificateInfo);
  const [isLoading, setIsLoading] = useState(false);

  const certificateApi = () => {
    setIsLoading(true);
    const url = "/batch/send-certificate";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };


    const formData = {
      email: certificateData.email,
      program: certificateData.program,
      name: certificateData.name,
      internCode: certificateData.internCode,
      gender: certificateData.gender,
      startdate: date.start,
      enddate: date.end,
      isInternship: certificateInfo.isInternship,
      isLor: certificateInfo.isLor,
    };

    axios
      .post(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);

        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured");
      });
  };

  return (
    <>
      <div className="enroll_student_certificate_container scrollbar-template">
        <div className="enroll_student_certificate_div">
          <div className="enroll_student_certificate_box">
            <img src={images["certificate_img.png"]} alt="" />
            <div className="overlay">
              <h4>{certificateData.program}</h4>
              <p>Training Certificate</p>
            </div>
          </div>
          {certificateInfo.isInternship && (
            <div className="enroll_student_certificate_box">
              <img src={images["certificate_img.png"]} alt="" />
              <div className="overlay">
                <h4>{certificateData.program}</h4>
                <p>Internship Certificate</p>
              </div>
            </div>
          )}
          {certificateInfo.isLor && (
            <div className="enroll_student_certificate_box">
              <img src={images["certificate_img.png"]} alt="" />
              <div className="overlay">
                <h4>{certificateData.program}</h4>
                <p>Letter Of Recommendation</p>
              </div>
            </div>
          )}
        </div>

        <p>
          <span>Note :- </span>
          <h6>
            Certificates allready send. Click resend to send the certificates
            again.
          </h6>
        </p>

        <button disabled={isLoading} onClick={certificateApi}>
          {isLoading ? <SmallLoader color={"#024c87"} /> : "Resend"}
        </button>
      </div>
    </>
  );
};

export default EnrollStudentCertificate;
