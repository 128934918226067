import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdStarOutline } from "react-icons/io";
import { LuForward } from "react-icons/lu";
import axios from "../../axios";
import moment from "moment";

const EnrollStudentTicket = ({ currentUserId }) => {
  const [ticketList, setTicketList] = useState([]);

  useEffect(() => {
    const url = `/leads/ticket-list/${currentUserId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setTicketList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUserId]);

  return (
    <>
      <div className="enroll_student_ticket_container scrollbar-template">
        {ticketList?.length > 0 ? (
          ticketList.map((data, i) => {
            return (
              <div className="enroll_student_ticket_list" key={i}>
                <img src={data.raisedBy.image} alt="" />
                <div className="enroll_student_ticket_list_content">
                  <div className="enroll_student_ticket_list_content_top">
                    <h2>
                      {data.raisedBy.username}{" "}
                      <span>{data.raisedBy.email}</span>
                    </h2>
                    <h3>
                      {moment(data.createdAt).format("DD MMM YYYY LT")}
                      {/* <span>
                        <IoMdStarOutline />
                        <LuForward />
                        <BsThreeDotsVertical size={18} />
                      </span> */}
                    </h3>
                  </div>
                  <p>{data.query}</p>
                </div>
              </div>
            );
          })
        ) : (
          <h4 className="text-center mt-4">No Ticket Created!</h4>
        )}
      </div>
      
    </>
  );
};

export default EnrollStudentTicket;
