import Company from "./pages/Company";
import Email from "./pages/Email";
import EnrollStudent from "./pages/EnrollStudent";
import EnrollStudentDetails from "./pages/EnrollStudentDetails";
import FormStudent from "./pages/FormStudent";
import FormTable from "./pages/FormTable";
import ImportLeadPreview from "./pages/ImportLeadPreview";
import ImportLeads from "./pages/ImportLeads";
import LeadStudent from "./pages/LeadStudent";
import LibraryResource from "./pages/LibraryResource";
import Login from "./pages/Login";
import ManagerEvaluation from "./pages/ManagerEvaluation";
import OperationOffer from "./pages/OperationOffer";
import Resources from "./pages/Resources";
import ResourcesUpload from "./pages/ResourcesUpload";
import TicketChats from "./pages/TicketChats";
import TicketQuery from "./pages/TicketQuery";

const RoutesArr = [
  {
    name: "Home",
    path: "/",
    key: "Home",
    route: "/",
    page: <FormTable />,
    private: true,
  },

  {
    name: "PaymentData",
    path: "/forms/payment-data",
    key: "PaymentData",
    route: "/forms/payment-data",
    page: <FormTable />,
    private: true,
  },

  {
    name: "EnrollData",
    path: "/forms/enroll-data",
    key: "EnrollData",
    route: "/forms/enroll-data",
    page: <FormTable />,
    private: true,
  },
  {
    name: "InvoiceData",
    path: "/forms/invoice-data",
    key: "InvoiceData",
    route: "/forms/invoice-data",
    page: <FormTable />,
    private: true,
  },

  {
    name: "ContactusData",
    path: "/forms/contactus-data",
    key: "ContactusData",
    route: "/forms/contactus-data",
    page: <FormTable />,
    private: true,
  },

  {
    name: "EnquireData",
    path: "/forms/enquiry-data",
    key: "EnquireData",
    route: "/forms/enquiry-data",
    page: <FormTable />,
    private: true,
  },

  {
    name: "OgtipData",
    path: "/forms/ogtip-data",
    key: "OgtipData",
    route: "/forms/ogtip-data",
    page: <FormTable />,
    private: true,
  },
  {
    name: "BrochureData",
    path: "/forms/brochure-data",
    key: "BrochureData",
    route: "/forms/brochure-data",
    page: <FormTable />,
    private: true,
  },
  {
    name: "Login",
    path: "/login",
    key: "Login",
    route: "/login",
    page: <Login />,
  },
  {
    name: "forms/summary/:id",
    path: "/forms/summary/:id",
    key: "forms/summary",
    route: "/forms/summary/:id",
    page: <FormStudent />,
    private: true,
  },
  {
    name: "leads/mylead",
    path: "/leads/mylead",
    key: "leads/mylead",
    route: "/leads/mylead",
    page: <LeadStudent />,
    private: true,
  },
  {
    name: "/leads/disputed-leads",
    path: "/leads/disputed-leads",
    key: "/leads/disputed-leads",
    route: "/leads/disputed-leads",
    page: <LeadStudent />,
    private: true,
  },
  {
    name: "/resources",
    path: "/resources",
    key: "/resources",
    route: "/resources",
    page: <Resources />,
    private: true,
  },
  {
    name: "/library",
    path: "/library",
    key: "/library",
    route: "/library",
    page: <LibraryResource />,
    private: true,
  },
  {
    name: "/resources/upload",
    path: "/resources/upload/:id",
    key: "/resources/upload",
    route: "/resources/upload/:id",
    page: <ResourcesUpload />,
    private: true,
  },
  {
    name: "/library/upload",
    path: "/library/upload/:id",
    key: "/library/upload",
    route: "/library/upload/:id",
    page: <ResourcesUpload />,
    private: true,
  },
  {
    name: "/company/hierarchy",
    path: "/company/hierarchy",
    key: "/company/hierarchy",
    route: "/company/hierarchy",
    page: <Company />,
    private: true,
  },
  {
    name: "/company/employee",
    path: "/company/employee",
    key: "/company/employee",
    route: "/company/employee",
    page: <Company />,
    private: true,
  },
  {
    name: "/lead-evaluation",
    path: "/lead-evaluation",
    key: "/lead-evaluation",
    route: "/lead-evaluation",
    page: <ManagerEvaluation />,
    private: true,
  },

  {
    name: "/operation-offer",
    path: "/operation-offer",
    key: "/operation-offer",
    route: "/operation-offer",
    page: <OperationOffer />,
    private: true,
  },
  {
    name: "/import-leads",
    path: "/import-leads",
    key: "/import-leads",
    route: "/import-leads",
    page: <ImportLeads />,
    private: true,
  },
  {
    name: "/import-leads-preview",
    path: "/import-leads-preview",
    key: "/import-leads-preview",
    route: "/import-leads-preview",
    page: <ImportLeadPreview />,
    private: true,
  },
  {
    name: "/ticket-queries",
    path: "/ticket-queries",
    key: "/ticket-queries",
    route: "/ticket-queries",
    page: <TicketQuery />,
    private: true,
  },
  {
    name: "/ticket-chats",
    path: "/ticket-chats",
    key: "/ticket-chats",
    route: "/ticket-chats",
    page: <TicketChats />,
    private: true,
  },

  {
    name: "/ticket-chats/:id",
    path: "/ticket-chats/:id",
    key: "/ticket-chats/:id",
    route: "/ticket-chats/:id",
    page: <TicketChats />,
    private: true,
  },
  {
    name: "/email",
    path: "/email",
    key: "/email",
    route: "/email",
    page: <Email />,
    private: true,
  },
  {
    name: "/enroll-student",
    path: "/enroll-student",
    key: "/enroll-student",
    route: "/enroll-student",
    page: <EnrollStudent />,
    private: true,
  },
  {
    name: "/enroll-student-detail/:id",
    path: "/enroll-student-detail/:id",
    key: "/enroll-student-detail/:id",
    route: "/enroll-student-detail/:id",
    page: <EnrollStudentDetails />,
    private: true,
  },
];

export default RoutesArr;
