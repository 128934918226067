import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import EmailHeader from "../components/Emailchats/EmailHeader";
import EmailChatsList from "../components/Emailchats/EmailChatsList";
import EmailChatsContent from "../components/Emailchats/EmailChatsContent";

const Email = () => {

  return (
    <>
      <LayoutWrapper>
        <div className="email_container">
          <Header />
          <EmailHeader />
          <EmailChatsList />
          {/* <EmailChatsContent /> */}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default Email;
