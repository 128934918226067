import React, { useState, useEffect } from "react";
import {
  BiChevronDown,
  BiChevronLeft,
  BiEdit,
  BiSearch,
  BiTrash,
} from "react-icons/bi";
import { images } from "../../actions/customFn";
import ReactPaginate from "react-paginate";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";

import axios from "../../axios";
import moment from "moment";
import Loader from "../../utils/Loader";
import { NavLink } from "react-router-dom";

const EnrollStudentTable = () => {
  const [searchToggle, setSearchToggle] = useState(false);
  const [buttonToggle, setButtonToggle] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchInp, setSearchInp] = useState("");
  const [enrollList, setEnrollList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    setIsLoading(true);
    const url = `/user/enroll-users`;

    let paramObj = {
      page: currentPage,
      limit: 10,
    };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    // if (selectedDate !== "") {
    //   paramObj.date = selectedDate;
    // }
    // if (selectedProgram !== "") {
    //   paramObj.program = selectedProgram;
    // }

    paramObj = { ...paramObj };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages, totalCount } = res.data;
        setTotalPages(totalPages);
        setEnrollList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
  }, [currentPage]);

  return (
    <>
      <div className="offer_letter_table_head">
        <h2>
          <BiChevronLeft size={35} />
          Enrolled Students
        </h2>
        <div className="offer_letter_table_head_icon_div">
          <div
            onClick={() => setSearchToggle(!searchToggle)}
            className="offer_letter_table_head_icon search_icon"
          >
            <BiSearch />

            {searchToggle && (
              <div className="offer_letter_table_head_search_input">
                <input type="text" placeholder="Search" />
                <BiSearch className="search_input_icon" />
              </div>
            )}
          </div>
          {/* <div className="offer_letter_table_head_icon">
            <BiEdit />
          </div> */}
        </div>
        {/* <button onClick={() => setButtonToggle(!buttonToggle)}>
          Add New <BiChevronDown size={26} />
          {buttonToggle && (
            <div className="offer_letter_table_dropdown">
              <span>
                Multiple Contact
                <input type="checkbox" />
              </span>
              <span>
                Upload File
                <input type="checkbox" />
              </span>
            </div>
          )}
        </button> */}
      </div>

      {isLoading ? (
        <Loader Height={"50vh"} />
      ) : (
        <div className="offer_letter_table_container scrollbar-template">
          <div className="offer_letter_table_div">
            <table>
              <thead>
                <tr>
                  <th className="checkbox_input">
                    {/* <input type="checkbox" /> */}
                    Sr No.
                  </th>
                  <th>Name</th>
                  <th>Email address</th>
                  <th>Phone Number</th>
                  <th>Domain</th>
                  <th>Created At</th>
                  <th>Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {enrollList?.length > 0 &&
                  enrollList.map((data, i) => {
                    return (
                      <tr>
                        <td className="checkbox_input">{i + 1}</td>
                        <td>
                          <div className="offer_letter_table_name">
                            {/* <img src={images["offer_letter_data_name.png"]} alt="" /> */}
                            <NavLink to={`/enroll-student-detail/${data._id}`}>
                              <span>
                                <h3>{data.username}</h3>
                              </span>
                            </NavLink>
                          </div>
                        </td>
                        <td>{data.email}</td>
                        <td>{data.contact_no}</td>
                        <td>{data.program_name}</td>
                        <td>
                          {moment(data.createdAt).format("DD MMM YYYY LT")}
                        </td>
                        <td>{data.country}</td>
                        {/* <td>
                        <BiTrash size={22} />
                      </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="operation_offer_paginate offer_letter_table_paginate ">
              <ReactPaginate
                breakLabel="..."
                pageCount={totalPages}
                onPageChange={handlePageClick}
                activeClassName={"active"}
                forcePage={currentPage !== 1 ? currentPage - 1 : ""}
                previousLabel={
                  <span className="prev-button">
                    <CgArrowLongLeft />
                    Previous
                  </span>
                }
                nextLabel={
                  <span className="next-button">
                    Next
                    <CgArrowLongRight />
                  </span>
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* operation_offer_paginate offer_letter_table_paginate .offer_letter_paginate_table */}
    </>
  );
};

export default EnrollStudentTable;
