import { setCountryData } from "../slice/rootSlice";
import { notifyDanger, notifySuccess } from "./customFn";
import axios from "../axios";

export const asyncCountryData = () => {
  return (dispatch) => {
    const url = "/user/country/country-code";
    axios
      .get(url)
      .then((res) => {
        const { data } = res.data;
        dispatch(setCountryData(data));
      })
      .catch((err) => {
        console.log("error");
        notifyDanger("some error occured");
      });
  };
};

export const asyncSendOfferLetter = (data, setIsLoading) => {
    return (dispatch) => {
      const url = `/user/offer-letter`;
      axios
        .post(url, data)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.response);
        });
    };
  };
