import { createSlice } from "@reduxjs/toolkit";

const rootSlice = createSlice({
  name: "rootSlice",
  initialState: {
    countryData: [],
  },
  reducers: {
    setCountryData: (state, action) => {
      state.countryData = action.payload;
    },
  },
});

export const { setCountryData } = rootSlice.actions;

export default rootSlice.reducer;
