import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { ImEnlarge2 } from "react-icons/im";
import { RiCloseLargeLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import Select from "react-select";
import { useSelector } from "react-redux";

const ComposeEmail = ({ setToggle, templateArr , setIsUpdate }) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [subject, setSubject] = useState("");
  const [batchList, setBatchList] = useState([]);
  const [body, setBody] = useState("");
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("");
  const profileData = useSelector((state) => state.profileData.userData);
  const [templateType, setTemplateType] = useState("");

  useEffect(() => {
    const templatemap = templateArr?.map((data) => {
      return { label: data.template_name, value: data._id };
    });
    setTemplateList(templatemap);
    getBatchList();
  }, []);


  const initialState = ()=>{
    setSelectedBatches([]);
    setSubject("");
    setBody("")
    setSelectedTemplate("");
    setTemplateType("");
    setTemplateTitle("")
  }

  useEffect(() => {
    if (selectedTemplate) {
      const url = `/leads/get-email-template/${selectedTemplate.value}`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };

      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          setSubject(data.template_subject);
          setBody(data.template_body);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedTemplate]);

  const sendEmail = () => {
    const url = `/leads/batches/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const batches = selectedBatches.map((val) => {
      return val.value;
    });

    const data = {
      subject,
      body,
      batches,
      template: selectedTemplate.value,
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState()
        setIsUpdate(p=>!p)
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err?.response?.data?.message || "Some error occured!")
      });
  };

  const getBatchList = () => {
    const url = `/leads/batch-list`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const batchData = data.map((itm) => {
          return { label: itm.batch_name, value: itm._id };
        });
        setBatchList(batchData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTemplate = () => {
    const url = `/leads/create-email-template`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      template_name: templateTitle,
      template_subject: subject,
      template_body: body,
      template_creater: profileData._id,
      template_type: templateType,
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTemplate = () => {
    const url = `/leads/update-email-template`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      template_name: templateTitle,
      template_subject: subject,
      template_body: body,
      template_creater: profileData._id,
      template_type: templateType,
      id: selectedTemplate.value,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={`compose_email_box ${fullScreen && "active"}`}>
        <div className="compose_email_top">
          <h2>Compose Mail</h2>
          <span>
            <ImEnlarge2 size={20} onClick={() => setFullScreen(!fullScreen)} />
            <RiCloseLargeLine size={20} onClick={() => setToggle(false)} />
          </span>
        </div>

        <div className="compose_email_input">
          <Select
            options={batchList}
            isMulti
            onChange={(e) => setSelectedBatches(e)}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Batches"
          />
          <h2>Initial Message</h2>
          {/* <div className="compose_email_search_input">
            <BiSearch size={20} className="search_icon" />
            <input type="text" placeholder="Search for a template" />
          </div> */}

          <Select
            options={templateList}
            onChange={(e) => setSelectedTemplate(e)}
            className="basic-multi-select"
            isClearable={true}
            value={selectedTemplate}
            classNamePrefix="select"
            placeholder="Select Template"
          />
          <input
            type="text"
            className="email_sub"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <div className="compose_email_edit_text">
            <ReactQuill
              theme="snow"
              value={body}
              onChange={(e) => setBody(e)}
            />
          </div>
        </div>
        <div className="position-relative">
          <div className="compose_email_bottom">
            {selectedTemplate ? (
              <button onClick={updateTemplate}>Update Template</button>
            ) : (
              <button onClick={() => setShowTemplate(!showTemplate)}>
                Save as template
              </button>
            )}

            <button onClick={() => sendEmail()}>Send</button>
          </div>
          {showTemplate && (
            <div className="compose_email_template">
              <div className="compose_email_template_top">
                <h2>Template Title</h2>
                <RxCross2 onClick={() => setShowTemplate(false)} size={20} />
              </div>
              <div className="compose_email_search_input">
                {/* <BiSearch size={20} className="search" /> */}
                <input
                  type="text"
                  placeholder="Enter a Title"
                  value={templateTitle}
                  onChange={(e) => setTemplateTitle(e.target.value)}
                />
              </div>
              <div className="compose_email_save">
                <h2>Save Template</h2>
                <div className="compose_email_save_option">
                  <span>
                    <input
                      type="radio"
                      checked={templateType === "private"}
                      onChange={() => {
                        if (templateType === "private") {
                          setTemplateType("");
                        } else {
                          setTemplateType("private");
                        }
                      }}
                    />
                    My Files
                  </span>
                  <span>
                    <input
                      type="radio"
                      checked={templateType === "public"}
                      onChange={() => {
                        if (templateType === "public") {
                          setTemplateType("");
                        } else {
                          setTemplateType("public");
                        }
                      }}
                    />
                    Visible to all
                  </span>
                </div>
                <button onClick={createTemplate}>Save</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComposeEmail;
