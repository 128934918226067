import React from "react";
import { BiChevronLeft, BiSearch } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";

const EmailHeader = () => {
  
  return (
    <>
      <div className="email_header">
        <h2>
          <BiChevronLeft size={34} />
          Email
        </h2>
        <div className="email_header_right_side">
          <div className="email_header_search_input">
            <BiSearch size={20} className="search_icon" />
            <input type="text" placeholder="Search" />
          </div>
          <div className="email_header_btn">
            <BsFilter size={22} />
          </div>
        </div>
      </div>

      {/*  */}
    </>
  );
};

export default EmailHeader;
