import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "../../axios";
import {
  confirmToast,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import { useSelector } from "react-redux";
import EvidenceModal from "./EvidenceModal";

const FormStudentNav = ({ formData }) => {
  const profileData = useSelector((state) => state.profileData.userData);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setShowModal] = useState(false);
  const createLead = () => {
    setIsLoading(true);
    const url = `/leads/create-lead`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const data = {
      username: formData.username,
      email: formData.email,
      contact_no: formData.contact_no,
      gender: formData.gender,
      address: formData.address,
      country: formData.country,
      program_name: formData.program_name,
      intern_code: formData.intern_code,
      amount_details: formData.amount_details,
      installment: formData.installment,
      cohort_details: formData.cohort_details,
      joining_date: formData.joining_date,
      referral_code: formData.referral_code,
      batch_name: formData.batch_name,
      claimedUsers: [{ userid: profileData._id }],
    };
    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  const isClaimedLead = () => {
    const url = `/leads/check-lead`;
    const paramObj = {
      email: formData.email,
      program_name: formData.program_name,
    };
    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { message, isExist } = res.data;
        if (isExist) {
          setShowModal(true);
        } else {
          createLead();
        }
        notifySuccess(message);
      })
      .catch((err) => {
        console.log(err);
        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  

  return (
    <>
      <div className="formstudent_nav_container">
        <div className="formstudent_nav_options">
          <NavLink to={"#"}>Summary</NavLink>
          <NavLink to={"/"}>Inbox</NavLink>
          <NavLink to={"/"}>Email Automation</NavLink>
        </div>
        <div className="formstudent_nav_btn">
          <button
            className={formData.claimable === false ? "active" : ""}
            disabled={formData.claimable === false ? true : false}
            onClick={() =>
              confirmToast("Are you want to claim this lead?", () =>
                isClaimedLead()
              )
            }
          >
            Claim Lead
          </button>
        </div>
      </div>
      {modalShow && (
        <EvidenceModal
          modalShow={modalShow}
          setShowModal={setShowModal}
          userData={formData}
        />
      )}
    </>
  );
};

export default FormStudentNav;
