import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { RxCross2 } from "react-icons/rx";
import axios from "../../axios";
import moment from "moment";

const EnrollStudentPaymentTable = ({ formData }) => {
  const [paymentItem, setPaymentItem] = useState([]);

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      const url = `/leads/get-payment-items`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
        params: {
          email: formData.email,
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          console.log(data);
          setPaymentItem(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formData]);

  return (
    <>
      <div className="enroll_student_payment_table scrollbar-template">
        <h2>Payment Page</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email address</th>
              <th>Phone Number</th>
              <th>Domain</th>
              <th>Country</th>
              <th>Payment Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paymentItem?.length > 0 &&
              paymentItem.map((data, i) => {
                return <EnrollItem data={data} i={i} formData={formData} />;
              })}
          </tbody>
        </table>
      </div>

      {/*  */}
    </>
  );
};

export default EnrollStudentPaymentTable;

const EnrollItem = ({ data, i, formData }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <tr key={i}>
        <td>
          <div className="enroll_student_table_name">
            {/* <img src={images["offer_letter_data_name.png"]} alt="" /> */}
            <span>
              <h3>{data.username}</h3>
            </span>
          </div>
        </td>
        <td>{data.email}</td>
        <td>{data.contact_no}</td>
        <td>{data.course}</td>
        <td>{data.country}</td>
        <td>
          {i === 0 ? (
            <button>Enrolled</button>
          ) : (
            <button style={{ backgroundColor: "#C00000" }}>Failed</button>
          )}
        </td>
        <td>
          <p onClick={() => setModal(!modal)}>details</p>
        </td>
      </tr>

      {modal && (
        <div className="enroll_student_payment_modal">
          <RxCross2 onClick={() => setModal(false)} className="cross_icon" />

          <div className="enroll_student_payment_modal_input_div">
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Olivia Rhye"
                value={data.username}
                disabled
              />
            </div>
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Email ID</label>
              <input
                type="email"
                placeholder="Olivia@gmail.com"
                value={data.email}
                disabled
              />
            </div>
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Address</label>
              <input
                type="text"
                placeholder="Your Address"
                value={data.billing_address}
                disabled
              />
            </div>
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Gender</label>
              <div className="enroll_student_payment_modal_buttons">
                <button
                  className={`${data.gender === "Female" ? "active" : ""}`}
                >
                  Female
                </button>
                <button className={`${data.gender === "Male" ? "active" : ""}`}>
                  Male
                </button>
              </div>
            </div>

            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Phone Number</label>
              <input type="text" value={data.contact_no} disabled />
            </div>
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Country</label>
              <select disabled>
                <option value="">{data.country}</option>
              </select>
            </div>
            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Program Name</label>
              <select disabled>
                <option value="">{data.course}</option>
              </select>
            </div>

            <div className="enroll_student_payment_modal_input">
              <label htmlFor="">Joining Date</label>
              <input type="date" value={data?.cohort_details?.date || ""} disabled />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
