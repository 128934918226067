import React from "react";
import ReactQuill from "react-quill";
import SmallLoader from "../../utils/SmallLoader";

const EnrollStudentOfferLetter = ({
  subject,
  setSubject,
  emailBody,
  setEmailBody,
  sendEmail,
  isLoading,
}) => {
  return (
    <>
      <div className="enroll_student_offer_letter_container scrollbar-template">
        <input
          type="text"
          className="email_sub"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <div className="enroll_student_offer_letter_edit_input">
          <ReactQuill
            theme="snow"
            value={emailBody}
            onChange={(e) => setEmailBody(e)}
          />
        </div>
        <button
          className="enroll_student_offer_letter_button"
          disabled={isLoading}
          onClick={() => sendEmail()}
        >
          {isLoading ? <SmallLoader color={"#024c87"} /> : "Send"}
        </button>
      </div>
    </>
  );
};

export default EnrollStudentOfferLetter;
